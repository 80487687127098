import { Text } from '@/components/shared/element/text';
import { styled, VariantProps } from '@/stitches.config';
import { ITest, ITracking } from '@/types/tracking';

const RadioGroup = styled('div', {
  display: 'flex',
  alignItems: 'center',
  overflow: 'hidden',
  br: '$rounded-1',
});

const RadioItem = styled('div', {
  position: 'relative',
  cursor: 'pointer',
  flexGrow: 1,
  flexBasis: 0,
  maxWidth: '100%',
  fontSize: 0,
});

const StyledRadio = styled('input', {
  all: 'unset',
  ap: 'none',
  position: 'absolute',
  left: 0,
  top: 0,
  width: '100%',
  height: '100%',
  cursor: 'pointer',
  boxSizing: 'border-box',
  '& ~ label': {
    position: 'relative',
    cursor: 'pointer',
    us: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textAlign: 'center',
    height: '$size-10',
    px: '$space-4',
    paddingTop: '$space-0-5',
  },
  variants: {
    theme: {
      light: {
        '& ~ label': {
          bc: '$gray300',
          color: '$gray1000',
        },
        '&:checked': {
          '& ~ label': {
            bc: '$gray1000',
            color: '$white',
          },
        },
      },
      dark: {
        '& ~ label': {
          bc: '$gray1000',
          color: '$white',
        },
        '&:checked': {
          '& ~ label': {
            bc: '$white',
            color: '$gray1000',
          },
        },
      },
    },
  },
});

interface RadioItem {
  value: string;
  label?: string;
  test?: ITest;
  tracking?: ITracking;
  disabled?: false | boolean;
}

interface RadioProps extends React.ComponentProps<typeof RadioGroup> {
  theme?: VariantProps<typeof StyledRadio>['theme'];
  items: RadioItem[];
  name: string;
  value?: string;
  onUpdate?: (name: string, value: string) => void;
}

export const CustomRadio: React.FC<RadioProps> = ({
  theme = 'light',
  items,
  name,
  value,
  onUpdate,
}: RadioProps) => {
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    onUpdate && onUpdate(name, e.target.value);
  };

  return (
    <RadioGroup>
      {items.map((item, index) => {
        return (
          <RadioItem key={index}>
            <StyledRadio
              theme={theme as VariantProps<typeof StyledRadio>['theme']}
              id={item?.value}
              name={name}
              value={item?.value}
              type="radio"
              checked={item?.value === value}
              aria-checked={item?.value === value}
              disabled={item?.disabled}
              onChange={handleChange}
              data-track={item.tracking?.dataTrack}
              data-track-section={item?.tracking?.dataTrackSection}
              data-track-value={item?.tracking?.dataTrackValue}
              data-track-text={item?.tracking?.dataTrackText}
              data-track-url={item?.tracking?.dataTrackUrl}
              data-test={`input_${item?.value}`}
            />
            <Text
              aria-label={item?.label}
              htmlFor={item?.value}
              as="label"
              size="text-button-2"
              font="bold"
              textTransform="uppercase"
              data-test={`label_${item?.value}`}
            >
              {item?.label}
            </Text>
          </RadioItem>
        );
      })}
    </RadioGroup>
  );
};
CustomRadio.displayName = 'CustomRadio';
