import type { BodyScrollOptions } from 'body-scroll-lock';
import { clearAllBodyScrollLocks, disableBodyScroll } from 'body-scroll-lock';
import dynamic from 'next/dynamic';
import { usePathname, useSearchParams } from 'next/navigation';
import React, {
  ComponentType,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { useCustomMountTransition } from '@/components/hooks/useCustomMountTransition';
import { useMediaQuery } from '@/components/hooks/useMediaQuery';
import { useMountTransition } from '@/components/hooks/useMountTransition';
import {
  DESKTOP_TRANSPARENT_SUBMENU_BACKGROUND_HEIGHT_KEY,
  PrimaryNavStyle,
  StyledHeader,
  StyledSubmenu,
} from '@/components/layouts/header/Header.styles';
import HeaderOverlay from '@/components/layouts/header/overlay';
import PrimaryNavLogo from '@/components/layouts/header/primary-nav-logo';
import PrimaryNavMenu from '@/components/layouts/header/primary-nav-menu';
import PrimaryNavMenuHamburger from '@/components/layouts/header/primary-nav-menu-hamburger';
import PrimaryNavMenuItem from '@/components/layouts/header/primary-nav-menu-item';
import PrimaryNavMenuItems from '@/components/layouts/header/primary-nav-menu-items';
import PrimaryNavMenuLink from '@/components/layouts/header/primary-nav-menu-link';
import PrimaryNavTopWithLogo from '@/components/layouts/header/primary-nav-top-with-logo';
import SecondaryNav from '@/components/layouts/header/secondary-nav';
import SubMenuCV from '@/components/layouts/header/submenu-cv';
import SubMenuLCV from '@/components/layouts/header/submenu-lcv';
import SubmenuWithImage from '@/components/layouts/header/submenu-with-image';
import { Button } from '@/components/shared/buttons/button';
import { Container } from '@/components/shared/layout/container';
import { Flex } from '@/components/shared/layout/flex';
import { Grid } from '@/components/shared/layout/grid';
import type { ImageProps } from '@/components/shared/media/image/Image.props';
import { useWindowSize } from '@/components/shared/utility/hooks';
import { config } from '@/stitches.config';
import { IMenu } from '@/types/layout/menu';

const HeaderMenuPanel = dynamic(
  () => import('@/components/layouts/header/menu-panel'),
  {
    ssr: false,
  }
);

const options: BodyScrollOptions = {
  reserveScrollBarGap: true,
};

interface IProps {
  menuBuyingTool?: IMenu[];
  menuMain?: IMenu[];
  menuModel?: IMenu[];
  showMenuPanel?: boolean;
  IconCar: ComponentType;
  isTransparentNavigation?: boolean;
  logo?: ImageProps;
}

const useSubmenuTransition = (startSubmenuTransition: any, setSubmenu: any) => {
  return useCallback(
    (index: number) => {
      startSubmenuTransition(() => setSubmenu(index));
    },
    [startSubmenuTransition, setSubmenu]
  );
};

const Header: React.FC<IProps> = (props: IProps) => {
  const [submenu, setSubmenu] = useState<number>(-1);
  const {
    currentStateNextFrame: submenuCurrentStateNextFrame,
    startTransition: startSubmenuTransition,
    transitionState: submenuTransitionState,
  } = useCustomMountTransition(submenu, 600);

  const handleSubmenuTransition = useSubmenuTransition(
    startSubmenuTransition,
    setSubmenu
  );

  const [toggleMenu, setToggleMenu] = useState<boolean>(false);
  const [toggleBuyingTools, setToggleBuyingTools] = useState<boolean>(false);
  const {
    startTransition: startToggleBuyingToolsTransition,
    shouldMount: toggleBuyingToolsShouldMount,
    trueFalseTransitionState: toggleBuyingToolsTrueFalse,
  } = useMountTransition({ isMounted: toggleBuyingTools, unmountDelay: 1000 });

  const [togglePanel, setTogglePanel] = useState<boolean>(false);
  const {
    startTransition: startTogglePanelTransition,
    shouldMount: togglePanelShouldMount,
    trueFalseTransitionState: togglePanelTrueFalse,
  } = useMountTransition({ isMounted: togglePanel, unmountDelay: 600 });

  const menuToggleBuyingTools = useRef(null);
  const menuTogglePanel = useRef(null);
  const menuToggle = useRef(null);
  const subMenuToggle = useRef(null);
  const refArray = useRef([]);
  const [
    desktopTranparentSubmenuBackgroundHeight,
    setDesktopTranparentSubmenuBackgroundHeight,
  ] = useState<number>(0);

  const pathname = usePathname();
  const searchParams = useSearchParams();

  const isMobile = useMediaQuery(config.media.maxlg);
  const windowSize = useWindowSize();

  const canDisplayTransparent = useMemo(
    () => props.isTransparentNavigation && !isMobile,
    [props.isTransparentNavigation, isMobile]
  );

  useEffect(() => {
    handleSubmenuTransition(-1);
    setToggleMenu(false);

    startToggleBuyingToolsTransition(() => setToggleBuyingTools(false));
    startTogglePanelTransition(() => setTogglePanel(false));

    clearAllBodyScrollLocks();
    const headerElement = document.querySelector('header');
    if (headerElement) {
      headerElement.classList.remove('is-product-menu-open');
    }
    document.body.style.overflow = '';
    document.body.style.paddingRight = '';
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname, searchParams]);

  useEffect(() => {
    if (isMobile) {
      if (!toggleMenu && submenu !== -1) {
        handleSubmenuTransition(-1);
      }
      if (togglePanel) {
        startTogglePanelTransition(() => setTogglePanel(false));
      }
    } else {
      if (toggleBuyingTools) {
        startToggleBuyingToolsTransition(() => setToggleBuyingTools(false));
      }
      if (toggleMenu) {
        setToggleMenu(false);
        handleSubmenuTransition(-1);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleMenu, submenu, toggleBuyingTools, togglePanel, isMobile]);

  useEffect(() => {
    if (isMobile) return;

    const height = document.querySelector(
      `[data-submenu-index="${submenu}"]`
    )?.clientHeight;

    if (!props.isTransparentNavigation && height) {
      setDesktopTranparentSubmenuBackgroundHeight(height + 88);
      return;
    }

    if (height) {
      setDesktopTranparentSubmenuBackgroundHeight(height);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submenu]);

  useEffect(() => {
    // NOTE: handle for section cv lineup e5 (@/components/sections/euro-5/line-up)
    const cube = document.querySelectorAll('[data-cube]');
    if (toggleMenu) {
      cube?.forEach((o) => {
        o?.classList.add('is-menu-open');
      });
      startToggleBuyingToolsTransition(() => setToggleBuyingTools(false));
    } else {
      cube?.forEach((o) => {
        o?.classList.remove('is-menu-open');
      });
      handleSubmenuTransition(-1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toggleMenu]);

  useEffect(() => {
    if (submenu === -1) return;
    startTogglePanelTransition(() => setTogglePanel(false));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submenu]);

  useEffect(() => {
    if (togglePanel) {
      handleSubmenuTransition(-1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [togglePanel]);

  // NOTE: check lock scroll
  useEffect(() => {
    if (
      toggleBuyingTools || // mobile: buying tools
      toggleMenu || // mobile: menu
      (!toggleMenu && submenu !== -1) || // desktop: menu
      togglePanel // desktop: panel
    ) {
      // mobile: buying tools
      if (toggleBuyingTools) {
        menuToggleBuyingTools.current &&
          disableBodyScroll(menuToggleBuyingTools.current, options);
        document.body.style.overflow = 'hidden';
        document.body.style.paddingRight = 'var(--scrollbar)';
        return;
      }

      // mobile: menu
      if (toggleMenu) {
        clearAllBodyScrollLocks();
        document.body.style.overflow = '';
        document.body.style.paddingRight = '';

        if (submenu === -1) {
          menuToggle.current && disableBodyScroll(menuToggle.current, options);
          document.body.style.overflow = 'hidden';
          document.body.style.paddingRight = 'var(--scrollbar)';
        } else {
          refArray.current[submenu] &&
            disableBodyScroll(refArray.current[submenu], options);
          document.body.style.overflow = 'hidden';
          document.body.style.paddingRight = 'var(--scrollbar)';
        }
        return;
      }

      // desktop: submenu
      if (!toggleMenu && submenu !== -1) {
        subMenuToggle.current &&
          disableBodyScroll(subMenuToggle.current, options);
        document.body.style.overflow = 'hidden';
        document.body.style.paddingRight = 'var(--scrollbar)';
        return;
      }

      // desktop: panel
      if (togglePanel) {
        menuTogglePanel.current &&
          disableBodyScroll(menuTogglePanel.current, options);
        document.body.style.overflow = 'hidden';
        document.body.style.paddingRight = 'var(--scrollbar)';
        return;
      }
    } else {
      clearAllBodyScrollLocks();
      document.body.style.overflow = '';
      document.body.style.paddingRight = '';
    }
  }, [toggleBuyingTools, togglePanel, submenu, toggleMenu]);

  useEffect(() => {
    if (!props.isTransparentNavigation) return;

    const height = document.querySelector(
      `[data-submenu-index="${submenu}"]`
    )?.clientHeight;

    if (height) {
      setDesktopTranparentSubmenuBackgroundHeight(height);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [windowSize.width]);

  const shouldHandleMouseOver =
    canDisplayTransparent && submenu !== -1
      ? () => handleSubmenuTransition(-1)
      : undefined;

  const handleToggleBuyingTools = useCallback(() => {
    startToggleBuyingToolsTransition(() =>
      setToggleBuyingTools((prevState) => !prevState)
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setToggleBuyingTools]);

  const handleTogglePanel = useCallback(() => {
    startTogglePanelTransition(() => setTogglePanel((prevState) => !prevState));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setTogglePanel]);

  const handleToggleMenu = useCallback(() => {
    setToggleMenu((prevState) => !prevState);
  }, [setToggleMenu]);

  return (
    <StyledHeader
      component="header"
      title="Main Header"
      className={submenu !== -1 ? 'is-submenu-open' : ''}
      css={{
        [DESKTOP_TRANSPARENT_SUBMENU_BACKGROUND_HEIGHT_KEY]: `${desktopTranparentSubmenuBackgroundHeight}px`,
      }}
      test={{
        dataTest: 'header',
      }}
      isTransparentNavigation={props.isTransparentNavigation}
      transparentNavigationAnimationOut={submenu === -1}
    >
      {!!toggleBuyingToolsShouldMount && (
        <HeaderOverlay
          onClick={handleToggleBuyingTools}
          className={toggleBuyingToolsTrueFalse ? 'is-buying-tools-open' : ''}
          css={{
            pointerEvents: toggleBuyingToolsTrueFalse ? 'all' : 'none',
            opacity: toggleBuyingToolsTrueFalse ? '1' : '0',
            '@lg': {
              display: 'none',
            },
          }}
        />
      )}
      <SecondaryNav
        className={toggleBuyingToolsTrueFalse ? 'is-buying-tools-open' : ''}
        menuBuyingTool={props.menuBuyingTool}
        menuModel={props.menuModel}
        onCloseBuyingTools={handleToggleBuyingTools}
        toggleBuyingTools={toggleBuyingTools}
        buyingToolsRef={menuToggleBuyingTools}
        isTransparentNavigation={props.isTransparentNavigation}
      />
      <PrimaryNavStyle
        position="relative"
        className={`${submenu !== -1 ? 'is-submenu-open' : ''} ${
          toggleBuyingToolsTrueFalse ? 'is-buying-tools-open' : ''
        }`}
        isTransparentNavigation={props.isTransparentNavigation}
        test={{
          dataTest: 'primary_nav',
        }}
      >
        <Container>
          <Grid
            alignItems="center"
            justifyContent="between"
            columnGap={props.isTransparentNavigation ? undefined : '6'}
            css={{
              height: 'var(--primary-nav-height)',
              gridTemplateColumns: 'minmax(auto, 137px) max-content',
              '@lg': {
                height: props.isTransparentNavigation
                  ? 'var(--desktop-transparent-nav-height)'
                  : 'var(--primary-nav-height)',
                gridTemplateColumns: props.isTransparentNavigation
                  ? 'minmax(104px, auto) 1fr'
                  : 'minmax(auto, 137px) max-content',
              },
            }}
          >
            <PrimaryNavLogo
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              logo={{ ...props.logo, priority: true, loading: 'eager' }}
              isClickMenu={submenu !== -1 || toggleBuyingTools}
              isTransparentNavigation={props.isTransparentNavigation}
              onMouseEnter={shouldHandleMouseOver}
            />
            <Flex
              alignItems="center"
              justifyContent="between"
              columnGap="6"
              css={{
                height: '100%',
                gridTemplateColumns: props.showMenuPanel
                  ? '1fr auto'
                  : undefined,
                display: props.showMenuPanel ? 'grid' : 'flex',
                '@lg': {
                  columnGap: props.isTransparentNavigation ? '0' : '$space-6',
                },
              }}
            >
              <PrimaryNavMenu
                className={toggleMenu ? 'is-nav-open' : undefined}
                isTransparentNavigation={props.isTransparentNavigation}
              >
                {(submenuTransitionState > -1 || submenu > -1) && (
                  <HeaderOverlay
                    className={submenu !== -1 ? 'is-submenu-open' : ''}
                    onClick={() => handleSubmenuTransition(-1)}
                    onMouseEnter={shouldHandleMouseOver}
                    css={{
                      '@lg': {
                        pointerEvents: submenu > -1 ? 'all' : 'none',
                        opacity: submenu > -1 ? '1' : '0',
                        zIndex: '-2',
                      },
                      '@maxlg': {
                        display: 'none',
                      },
                    }}
                  />
                )}
                <PrimaryNavMenuItems
                  menuRef={menuToggle}
                  isTransparentNavigation={props.isTransparentNavigation}
                  className={submenu !== -1 ? 'is-submenu-open' : undefined}
                >
                  {props?.menuMain?.map((o, i) => (
                    <PrimaryNavMenuItem
                      key={i}
                      css={!!o.showpanel && { '@lg': { display: 'none' } }}
                    >
                      <PrimaryNavMenuLink
                        key={i}
                        {...o}
                        submenuId={submenu}
                        index={i}
                        isToggleMenu={toggleMenu}
                        onClickSubmenu={
                          canDisplayTransparent
                            ? undefined
                            : () => {
                                submenu === i
                                  ? handleSubmenuTransition(-1)
                                  : submenu !== i && handleSubmenuTransition(i);
                              }
                        }
                        onMouseEnter={
                          canDisplayTransparent
                            ? () => submenu !== i && handleSubmenuTransition(i)
                            : undefined
                        }
                        isTransparentNavigation={props.isTransparentNavigation}
                      />
                      {!!o.submenu &&
                        o.submenu.length > 0 &&
                        (submenuTransitionState === i || submenu === i) && (
                          <StyledSubmenu
                            ref={subMenuToggle}
                            data-test="navigation_submenu"
                            data-submenu-index={i}
                            backgroundColor="white"
                            className={
                              canDisplayTransparent
                                ? i === submenu
                                  ? 'enter is-submenu-open'
                                  : 'leave'
                                : i === submenu &&
                                  submenuCurrentStateNextFrame === i
                                ? 'enter is-submenu-open'
                                : 'leave'
                            }
                            isTransparentNavigation={
                              props.isTransparentNavigation
                            }
                            css={
                              o.layout !== 1 && o.layout !== 2
                                ? {
                                    backgroundColor: 'white !important',
                                  }
                                : undefined
                            }
                          >
                            {o.layout === 1 && (
                              <SubMenuCV
                                {...o}
                                itemsRef={refArray}
                                index={i}
                                onClickBackMenu={() =>
                                  handleSubmenuTransition(-1)
                                }
                                onClickCloseMenu={handleToggleMenu}
                              />
                            )}
                            {o.layout === 2 && (
                              <SubMenuLCV
                                {...o}
                                itemsRef={refArray}
                                index={i}
                                onClickBackMenu={() =>
                                  handleSubmenuTransition(-1)
                                }
                                onClickCloseMenu={handleToggleMenu}
                              />
                            )}
                            {o.layout !== 1 && o.layout !== 2 && (
                              <SubmenuWithImage
                                {...o}
                                itemsRef={refArray}
                                index={i}
                                onClickBackMenu={() =>
                                  handleSubmenuTransition(-1)
                                }
                                onClickCloseMenu={handleToggleMenu}
                              />
                            )}
                          </StyledSubmenu>
                        )}
                    </PrimaryNavMenuItem>
                  ))}
                  {!!toggleMenu &&
                    props?.menuModel
                      ?.filter((menu: IMenu) => menu.active !== true)
                      .map((menu: IMenu, index: number) => (
                        <PrimaryNavMenuItem
                          key={index}
                          variant="model"
                          css={{ '@lg': { display: 'none' } }}
                        >
                          <PrimaryNavMenuLink
                            {...menu}
                            variant="model"
                            IconCar={props.IconCar}
                          />
                        </PrimaryNavMenuItem>
                      ))}
                </PrimaryNavMenuItems>
                {!!toggleMenu && (
                  <PrimaryNavTopWithLogo
                    variant="main-nav"
                    className={submenu !== -1 ? 'is-submenu-open' : undefined}
                    onClickCloseMenu={handleToggleMenu}
                  >
                    <PrimaryNavLogo logo={props.logo} />
                  </PrimaryNavTopWithLogo>
                )}
              </PrimaryNavMenu>
              <Button
                label="สนใจรถ"
                size="md"
                variant={
                  !props.isTransparentNavigation || toggleBuyingTools
                    ? 'button-red'
                    : 'button-glost'
                }
                onClick={handleToggleBuyingTools}
                tracking={{
                  dataTrack: 'primary-nav',
                  dataTrackText: 'สนใจรถ',
                }}
                test={{
                  dataTest: 'interest_button',
                }}
                css={{
                  '@lg': {
                    display: 'none',
                  },
                }}
              />
              <PrimaryNavMenuHamburger
                onClickMenu={handleToggleMenu}
                isClickMenu={toggleBuyingTools}
                isTransparentNavigation={props.isTransparentNavigation}
                tracking={{
                  dataTrack: 'primary-nav',
                  dataTrackText: 'Navigation Menu Button',
                }}
                test={{
                  dataTest: 'navigation_menu_button',
                }}
                css={{
                  '@lg': {
                    display: 'none',
                  },
                }}
              />
              {!!props.showMenuPanel && (
                <PrimaryNavMenuHamburger
                  onClickMenu={handleTogglePanel}
                  isClickMenu={submenu !== -1}
                  onMouseEnter={shouldHandleMouseOver}
                  isTransparentNavigation={props.isTransparentNavigation}
                  tracking={{
                    dataTrack: 'primary-nav',
                    dataTrackText: 'Menu Panel Button',
                  }}
                  test={{
                    dataTest: 'menu_panel_button',
                  }}
                  css={{
                    '@maxlg': {
                      display: 'none',
                    },
                  }}
                />
              )}
            </Flex>
          </Grid>
        </Container>

        {!!props.showMenuPanel && !!togglePanelShouldMount && (
          <HeaderMenuPanel
            menuMain={props.menuMain}
            menuTogglePanelRef={menuTogglePanel}
            togglePanel={togglePanelTrueFalse}
            onClick={handleTogglePanel}
          >
            <PrimaryNavTopWithLogo
              variant="panel"
              onClickCloseMenu={handleTogglePanel}
            >
              <PrimaryNavLogo logo={props.logo} />
            </PrimaryNavTopWithLogo>
          </HeaderMenuPanel>
        )}
      </PrimaryNavStyle>
    </StyledHeader>
  );
};
Header.displayName = 'Header';
export default Header;
