import React from 'react';

import { styled } from '@/stitches.config';

const PrimaryNavMenuStyle = styled('nav', {
  '@lg': {
    height: '100%',
  },
  '@maxlg': {
    position: 'fixed',
    left: '100%',
    top: '$space-0',
    width: '100%',
    height: 'var(--viewport-height)',
    backgroundColor: '$white',
    zIndex: '$2',
    transitionProperty: 'transform',
    transitionDuration: '0.6s',
    transitionTimingFunction: 'var(--transition-easing)',
    willChange: 'transform',
    '@supports (height: 100svh)': {
      height: '100svh',
    },
    '&.is-nav-open': {
      transform: 'translateX(-100%)',
    },
  },
  variants: {
    isTransparentNavigation: {
      true: {
        '@lg': {
          justifySelf: 'center',
        },
      },
    },
  },
});

interface PrimaryNavMenuProps {
  children?: React.ReactNode;
  className?: string;
  isTransparentNavigation?: boolean;
}

const PrimaryNavMenu: React.FC<PrimaryNavMenuProps> = ({
  children,
  className,
  isTransparentNavigation,
}: PrimaryNavMenuProps) => {
  return (
    <PrimaryNavMenuStyle
      isTransparentNavigation={isTransparentNavigation}
      data-test="navigation_menu"
      className={className}
    >
      {children}
    </PrimaryNavMenuStyle>
  );
};

PrimaryNavMenu.displayName = 'PrimaryNavMenu';

export default PrimaryNavMenu;
