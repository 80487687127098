import React, { useState } from 'react';

import CloseButtonDesktop from '@/components/layouts/header/close-button-desktop';
import PrimaryNavTopWithBackMenu from '@/components/layouts/header/primary-nav-top-with-back-menu';
import SubmenuModelLink from '@/components/layouts/header/submenu-model-link';
import { Icon } from '@/components/shared/element/icons';
import { Text } from '@/components/shared/element/text';
import { CustomRadio } from '@/components/shared/form/custom-radio';
import { Box } from '@/components/shared/layout/box';
import { Container } from '@/components/shared/layout/container';
import { Flex } from '@/components/shared/layout/flex';
import { styled } from '@/stitches.config';
import { IMenu } from '@/types/layout/menu';

const SubmenuCv = styled('div', {
  position: 'relative',
  zIndex: 3,
  '@lg': {
    paddingTop: '$space-10',
    paddingBottom: '$space-10',
  },
  '@maxlg': {
    height: 'calc(var(--viewport-height) - var(--primary-nav-height))',
    overflowX: 'hidden',
    overflowY: 'auto',
    '-webkit-overflow-scrolling': 'touch',
  },
});

const SubmenuCvTabs = styled('ul', {
  display: 'none',
  '@lg': {
    position: 'relative',
    display: 'flex',
    columnGap: '$space-6',
    marginBottom: '$space-10',
    borderBottom: '1px solid $gray300',
  },
});

const SubmenuCvTab = styled('li', {
  position: 'relative',
  paddingLeft: '$space-2',
  paddingRight: '$space-2',
  paddingBottom: '$space-1',
  minWidth: '$size-26',
  height: '$size-10',
  textAlign: 'center',
  cursor: 'pointer',
  overflow: 'hidden',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '&::after': {
    content: '""',
    display: 'block',
    position: 'absolute',
    bottom: '$space-0',
    left: '$space-0',
    width: '100%',
    height: '$size-1',
    pointerEvents: 'none',
    backgroundColor: '$primary',
    zIndex: '1',
    transform: 'translateY(100%)',
    transitionProperty: 'transform',
    transitionDelay: 'var(--transition-delay)',
    transitionDuration: 'var(--transition-duration)',
    transitionTimingFunction: 'var(--transition-easing)',
  },
  '&.is-current': {
    '&::after': {
      transform: 'translateY(0)',
    },
  },
  '@hover': {
    '&:hover': {
      '&::after': {
        transform: 'translateY(0)',
      },
    },
  },
});

const SubmenuCvGroups = styled('div', {
  // '@2xl': {
  //   paddingLeft: '$space-12',
  //   paddingRight: '$space-12',
  // },
});

const SubmenuCvGroup = styled('div', {
  position: 'relative',
  '@lg': {
    display: 'none',
    '&.is-current': {
      display: 'block',
    },
  },
  '@maxlg': {
    backgroundColor: '$gray200',
    borderBottom: '1px solid $gray200',
  },
});

const SubMenuCarGroup = styled('div', {
  display: 'grid',
  gap: '$space-4',
  gridTemplateColumns: 'repeat(2, 1fr)',
  padding: '$space-6',
  '@xs': {
    gap: '$space-6',
  },
  '@sm': {
    padding: '$space-8 $space-12',
  },
  '@md': {
    gridTemplateColumns: 'repeat(3, 1fr)',
  },
  '@lg': {
    display: 'flex',
    flexWrap: 'wrap',
    columnGap: '$space-6',
    padding: '$space-0',
  },
  '@xl': {
    columnGap: '$space-16',
  },
});

const SubmenuCvItems = styled(Box, {
  variants: {
    columns: {
      '1': {},
      '2': {
        gridColumn: '1 / span 2',
      },
      '3': {
        gridColumn: '1 / span 2',
        '@md': {
          gridColumn: '1 / span 3',
        },
      },
      '4': {
        gridColumn: '1 / span 2',
        '@md': {
          gridColumn: '1 / span 3',
        },
      },
    },
  },
});

const SubmenuCvItem = styled('div', {
  display: 'grid',
  gap: '$space-6',
  '@lg': {
    // display: 'flex',
    gap: '$space-0',
  },

  '@xl': {
    gap: '$space-6',
  },
  '@maxlg': {
    height: '100%',
  },
  variants: {
    columns: {
      '1': {},
      '2': {
        gridTemplateColumns: 'repeat(2, 1fr)',
      },
      '3': {
        gridTemplateColumns: 'repeat(2, 1fr)',
        '@md': {
          gridTemplateColumns: 'repeat(3, 1fr)',
        },
      },
      '4': {
        gridTemplateColumns: 'repeat(2, 1fr)',
        '@md': {
          gridTemplateColumns: 'repeat(3, 1fr)',
        },
        '@lg': {
          gridTemplateColumns: 'auto',
          gridAutoFlow: 'column',
        },
      },
    },
  },
});

const SubmenuToggle = styled(Flex, {
  paddingLeft: '$space-6',
  paddingRight: '$space-6',
  height: 'var(--primary-nav-height)',
  cursor: 'pointer',
  backgroundColor: '$gray300',
  '&.is-current': {
    '& i': {
      transform: 'rotate(180deg)',
    },
  },
  '@sm': {
    paddingLeft: '$space-12',
    paddingRight: '$space-12',
  },
  '@lg': {
    display: 'none',
  },
});

const SubmenuCvEngineType = styled('div', {
  position: 'sticky',
  top: '$space-0',
  left: '$space-0',
  width: '100%',
  zIndex: '1',
  backgroundColor: '$white',
  padding: '$space-4 $space-6',
  '@sm': {
    padding: '$space-4 $space-12',
  },
  '@lg': {
    position: 'relative',
    top: 'auto',
    left: 'auto',
    padding: '$space-0 $space-0 $space-6',
    '& > *': {
      maxWidth: '$size-80',
    },
  },
});

interface SubMenuCVProps extends IMenu {
  onClickBackMenu?: () => void;
  onClickCloseMenu?: () => void;
  itemsRef?: React.RefObject<HTMLDivElement[]> | undefined;
  index?: number;
}

const CarItem = (props: IMenu) => {
  const [toggle, setToggle] = useState<boolean>(true);

  return (
    <>
      <SubmenuToggle
        alignItems="center"
        justifyContent="between"
        onClick={() => setToggle(!toggle)}
        className={toggle ? 'is-current' : ''}
        data-test="navigation_toggle"
      >
        <Text
          size="text-body-1"
          font="bold"
          data-test="navigation_toggle_title"
        >
          {props.label}
        </Text>
        <Icon icon="expand-more" />
      </SubmenuToggle>
      {!!toggle && !!props.submenu && props.submenu.length > 0 && (
        <SubMenuCarGroup>
          {props.submenu.map((sub, index) => (
            <SubmenuCvItems
              key={index}
              display="flex"
              direction="column"
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              columns={sub.submenu.length >= 4 ? '4' : sub.submenu.length}
              test={{
                dataTest: 'model_items',
              }}
            >
              {!!sub.label && (
                <Text
                  size="text-body-2"
                  font="bold"
                  variant="text-gray"
                  data-test="model_category_title"
                >
                  {sub.label}
                </Text>
              )}
              {!!sub.submenu && sub.submenu.length > 0 && (
                <SubmenuCvItem
                  columns={sub.submenu.length >= 4 ? '4' : sub.submenu.length}
                  data-test="model_item"
                >
                  {sub.submenu.map((j, index) => (
                    <SubmenuModelLink
                      key={index}
                      variant="cv"
                      dataTrack="primary-nav"
                      dataTest="submenu_link"
                      {...j}
                    />
                  ))}
                </SubmenuCvItem>
              )}
            </SubmenuCvItems>
          ))}
        </SubMenuCarGroup>
      )}
    </>
  );
};

const SubMenuCV: React.FC<SubMenuCVProps> = ({
  onClickBackMenu,
  onClickCloseMenu,
  itemsRef,
  index,
  ...rest
}: SubMenuCVProps) => {
  const [selectedEngineType, setSelectedEngineType] =
    useState<string>('euro-5');
  const [selectedTab, setSelectedTab] = useState<number>(0);

  const handleSelectedTab = (subIndex: number) => {
    setSelectedTab(subIndex);
  };

  return (
    <Container size="desktop-only">
      <PrimaryNavTopWithBackMenu
        label={rest.label}
        onClickBackMenu={onClickBackMenu}
        onClickCloseMenu={onClickCloseMenu}
        isHideDesktop
      />

      <SubmenuCv
        ref={(ref) => {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          itemsRef?.current && (itemsRef.current[index] = ref);
        }}
      >
        <SubmenuCvEngineType data-test="navigation_switch_model">
          <CustomRadio
            name="engine_type"
            value={selectedEngineType}
            items={[
              {
                label: 'EURO 5',
                value: 'euro-5',
                tracking: {
                  dataTrack: 'primary-nav',
                  dataTrackText: 'EURO 5',
                  dataTrackValue: 'euro-5',
                },
              },
              {
                label: 'EURO 3',
                value: 'euro-3',
                tracking: {
                  dataTrack: 'primary-nav',
                  dataTrackText: 'EURO 3',
                  dataTrackValue: 'euro-3',
                },
              },
            ]}
            onUpdate={(name, value) => {
              setSelectedEngineType(value);
            }}
          />
        </SubmenuCvEngineType>
        {selectedEngineType === 'euro-5' && (
          <>
            <SubmenuCvTabs data-test="navigation_tabs">
              {rest?.submenu?.map((sub, subIndex) => (
                <SubmenuCvTab
                  key={subIndex}
                  className={subIndex === selectedTab ? 'is-current' : ''}
                  onClick={() => handleSelectedTab(subIndex)}
                  data-test="navigation_tab"
                >
                  <Text
                    size="text-body-1"
                    font={subIndex === selectedTab ? 'bold' : 'base'}
                    data-test="tab_title"
                  >
                    {sub.label}
                  </Text>
                </SubmenuCvTab>
              ))}
            </SubmenuCvTabs>
            <SubmenuCvGroups data-test="navigation_groups">
              {rest?.submenu?.map((sub, subIndex) => (
                <SubmenuCvGroup
                  key={subIndex}
                  className={subIndex === selectedTab ? 'is-current' : ''}
                  data-test="navigation_group"
                >
                  <CarItem {...sub} />
                </SubmenuCvGroup>
              ))}
            </SubmenuCvGroups>
          </>
        )}
        {selectedEngineType === 'euro-3' && (
          <>
            <SubmenuCvTabs data-test="navigation_tabs">
              {rest?.submenu2?.map((sub, subIndex2) => (
                <SubmenuCvTab
                  key={subIndex2}
                  className={subIndex2 === selectedTab ? 'is-current' : ''}
                  onClick={() => handleSelectedTab(subIndex2)}
                  data-test="navigation_tab"
                >
                  <Text
                    size="text-body-1"
                    font={subIndex2 === selectedTab ? 'bold' : 'base'}
                    data-test="tab_title"
                  >
                    {sub.label}
                  </Text>
                </SubmenuCvTab>
              ))}
            </SubmenuCvTabs>
            <SubmenuCvGroups data-test="navigation_groups">
              {rest?.submenu2?.map((sub, subIndex2) => (
                <SubmenuCvGroup
                  key={subIndex2}
                  className={subIndex2 === selectedTab ? 'is-current' : ''}
                  data-test="navigation_group"
                >
                  <CarItem {...sub} />
                </SubmenuCvGroup>
              ))}
            </SubmenuCvGroups>
          </>
        )}
        <CloseButtonDesktop onClickCloseMenu={onClickCloseMenu} />
      </SubmenuCv>
    </Container>
  );
};

SubMenuCV.displayName = 'SubMenuCV';

export default SubMenuCV;
