import React from 'react';

import CloseButtonDesktop from '@/components/layouts/header/close-button-desktop';
import PrimaryNavTopWithBackMenu from '@/components/layouts/header/primary-nav-top-with-back-menu';
import SubmenuModelLink from '@/components/layouts/header/submenu-model-link';
import { Text } from '@/components/shared/element/text';
import { Box } from '@/components/shared/layout/box';
import { Container } from '@/components/shared/layout/container';
import { styled } from '@/stitches.config';
import { IMenu } from '@/types/layout/menu';

const SubmenuLcvStyled = styled(Box, {
  zIndex: 3,
  '@maxlg': {
    height: 'calc(var(--viewport-height) - var(--primary-nav-height))',
    overflow: 'hidden auto',
    '-webkit-overflow-scrolling': 'touch',
  },
});

const SubmenuLcvRow = styled('div', {
  '@lg': {
    display: 'grid',
    gridTemplateColumns: 'minmax(auto, 768px) auto',
    gap: '$space-12',
  },
  '@xl': {
    gridTemplateColumns: 'minmax(auto, 800px) auto',
  },
});

const SubmenuLcvColumn = styled('div', {
  position: 'relative',
  padding: '$space-6',
  '@sm': {
    padding: '$space-8 $space-12',
  },
  '@lg': {
    padding: '$space-0',
  },
  '@maxlg': {
    '&:not(:last-child)': {
      borderBottom: '1px solid $gray300',
    },
  },
  variants: {
    size: {
      '1': {
        '@lg': {
          '&::before': {
            content: '""',
            display: 'block',
            position: 'absolute',
            top: '$space-0',
            left: 'calc(100% + 24px)',
            width: 1,
            height: '100%',
            pointerEvents: 'none',
            backgroundColor: '$gray400',
          },
        },
      },
      '2': {
        mx: 'auto',
      },
    },
  },
});

const SubmenuLcvItems = styled('div', {
  '@md': {
    display: 'grid',
    gap: '$space-6',
    gridTemplateColumns: 'repeat(3, 1fr)',
  },
  variants: {
    size: {
      '1': {
        '@lg': {
          gridTemplateColumns: 'repeat(3, minmax(min-content, 240px))',
        },
      },
      '2': {
        '@lg': {
          gridTemplateColumns: 'repeat(1, minmax(min-content, 240px))',
        },
      },
    },
    onePerRow: {
      true: {
        '@lg': {
          gridTemplateColumns: 'repeat(1, minmax(min-content, 240px))',
        },
      },
    },
  },
});

interface SubMenuLCVProps extends IMenu {
  onClickBackMenu?: () => void;
  onClickCloseMenu?: () => void;
  itemsRef?: React.RefObject<HTMLDivElement[]> | undefined;
  index: number;
}

const SubMenuLCV: React.FC<SubMenuLCVProps> = ({
  onClickBackMenu,
  onClickCloseMenu,
  itemsRef,
  index,
  ...rest
}: SubMenuLCVProps) => {
  return (
    <Container size="desktop-only">
      <PrimaryNavTopWithBackMenu
        label={rest.label}
        onClickBackMenu={onClickBackMenu}
        onClickCloseMenu={onClickCloseMenu}
        isHideDesktop
      />
      <SubmenuLcvStyled
        position="relative"
        paddingY={{ '@lg': '12' }}
        backgroundColor={{ '@maxlg': 'gray200' }}
        ref={(ref) => {
          ref && itemsRef?.current && (itemsRef.current[index] = ref);
        }}
      >
        <SubmenuLcvRow data-test="submenu_items">
          {rest?.submenu?.map((sub, submenuColumnIndex) => (
            <SubmenuLcvColumn
              key={submenuColumnIndex}
              size={sub?.submenu?.length === 1 || sub.isFlexColumn ? '2' : '1'}
            >
              {!!sub.label && (
                <Text
                  size="text-sub-h2"
                  font="bold"
                  variant="text-gray"
                  css={{ '@md': { marginBottom: '$space-4' } }}
                  data-test="submenu_category_title"
                >
                  {sub.label}
                </Text>
              )}
              <SubmenuLcvItems
                size={
                  sub?.submenu?.length === 1 || sub.isFlexColumn ? '2' : '1'
                }
                onePerRow={sub.isFlexColumn}
              >
                {sub?.submenu?.map((subMenuItem, subMenuItemIndex) => (
                  <React.Fragment
                    key={`submenuColumn${submenuColumnIndex}${subMenuItemIndex}`}
                  >
                    <SubmenuModelLink
                      variant="lcv"
                      dataTrack="primary-nav"
                      dataTest="submenu_link"
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      // @ts-ignore
                      {...subMenuItem.submenu[0]}
                    />
                  </React.Fragment>
                ))}
              </SubmenuLcvItems>
            </SubmenuLcvColumn>
          ))}
        </SubmenuLcvRow>
        <CloseButtonDesktop onClickCloseMenu={onClickCloseMenu} />
      </SubmenuLcvStyled>
    </Container>
  );
};

SubMenuLCV.displayName = 'SubMenuLCV';

export default SubMenuLCV;
