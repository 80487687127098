import React from 'react';

import { Box } from '@/components/shared/layout/box';
import { CSS } from '@/stitches.config';

interface HeaderOverlayProps extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode;
  css?: CSS;
  onClick?: () => void;
  className?: string;
}

const HeaderOverlay: React.FC<HeaderOverlayProps> = ({
  children,
  css,
  className,
  ...rest
}: HeaderOverlayProps) => {
  return (
    <Box
      position="fixed"
      width="full"
      height="h-screen"
      top="0"
      left="0"
      backgroundColor="backdrop"
      cursor="pointer"
      css={{
        ...css,
        transition: 'opacity 1s var(--transition-easing)',
        willChange: 'opacity',
      }}
      className={className}
      {...rest}
    >
      {children}
    </Box>
  );
};

HeaderOverlay.displayName = 'HeaderOverlay';

export default HeaderOverlay;
