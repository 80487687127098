import React from 'react';

import { Text } from '@/components/shared/element/text';
import { styled } from '@/stitches.config';

interface IProps {
  css?: any;
  flip?: boolean;
}

const BadgeWrap = styled('div', {
  height: '$size-5',
  px: '$space-1-5',
  position: 'absolute',
  right: '$space-0',
  top: '$space-0',
  us: 'none',
  transform: 'translateY(-50%) skewX(-24deg)',
  transformOrigin: 'left bottom',
  bc: '$primary',
  pe: 'none',
  '&::before, &::after': {
    content: '""',
    bc: '$primary',
    position: 'absolute',
    top: '$space-0',
    height: '100%',
  },
  '&::before': {
    width: 4,
    right: -5,
  },
  '&::after': {
    width: 2,
    right: -8,
  },
  '*': {
    color: '$white !important',
    transform: 'skew(22deg, 0deg)',
    lineHeight: '20px !important',
    whiteSpace: 'nowrap',
  },
  variants: {
    flip: {
      true: {
        '&::after': {
          width: 4,
          left: -9,
        },
        '&::before': {
          width: 2,
          left: -3.4,
        },
      },
    },
  },
  defaultVariants: {
    flip: true,
  },
});

const View: React.FunctionComponent<IProps> = (props: IProps) => {
  return (
    <BadgeWrap css={props.css} flip={props.flip}>
      <Text
        className="new-badge"
        variant="text-white"
        size="2xs"
        font="display"
      >
        รุ่นพิเศษ
      </Text>
    </BadgeWrap>
  );
};

View.displayName = 'SharedElementNewBadge';
export default View;
