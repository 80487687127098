import React from 'react';

import { ButtonIcon } from '@/components/shared/buttons/button-icon';

interface CloseButtonDesktopProps {
  onClickCloseMenu?: () => void;
}

const CloseButtonDesktop: React.FC<CloseButtonDesktopProps> = ({
  onClickCloseMenu,
}: CloseButtonDesktopProps) => {
  return (
    <ButtonIcon
      icon="close"
      variant="button-icon-color-gray-dark"
      size="lg"
      isTransparent
      onClick={onClickCloseMenu}
      test={{ dataTest: 'close_button' }}
      css={{
        position: 'absolute',
        top: '$space-4',
        right: '$space-0',
        cursor: 'pointer',
        zIndex: '1',
        '@maxlg': {
          display: 'none',
        },
      }}
    />
  );
};

CloseButtonDesktop.displayName = 'CloseButtonDesktop';

export default CloseButtonDesktop;
