import React from 'react';

import { styled } from '@/stitches.config';

const PrimaryNavMenuItemsStyle = styled('ul', {
  variants: {
    isTransparentNavigation: {
      true: {},
    },
    variant: {
      'main-menu': {
        '@lg': {
          display: 'flex',
          columnGap: '$space-6',
          height: '100%',
        },
        '@maxlg': {
          position: 'relative',
          width: '100%',
          py: 'var(--primary-nav-height)',
          height: 'var(--viewport-height)',
          overflowX: 'hidden',
          overflowY: 'auto',
          '-webkit-overflow-scrolling': 'touch',
          '&.is-submenu-open': {
            '& > li': {
              '& > *:first-child': {
                transform: 'translateX(-100%)',
              },
            },
          },
        },
      },
      submenu: {
        '@lg': {
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          rowGap: '$space-4',
          minWidth: '264px',
        },
      },
      'submenu-level-2': {
        '@lg': {
          display: 'flex',
          flexDirection: 'column',
          rowGap: '$space-2',
          position: 'absolute',
          left: '100%',
          top: '$space-0',
          width: '100%',
          opacity: '0',
          visibility: 'hidden',
          transitionProperty: 'opacity',
          transitionDuration: '0.6s',
          transitionTimingFunction: 'var(--transition-easing)',
          willChange: 'opacity',
        },
      },
      panel: {
        height: 'calc(var(--viewport-height) - var(--primary-nav-height))',
        overflowX: 'hidden',
        overflowY: 'auto',
        '-webkit-overflow-scrolling': 'touch',
      },
    },
  },
  compoundVariants: [
    {
      variant: 'main-menu',
      isTransparentNavigation: 'true',
      css: {
        '@lg': {
          columnGap: '$space-8',
          alignItems: 'center',
        },
      },
    },
  ],
  defaultVariants: {
    variant: 'main-menu',
  },
});

interface PrimaryNavMenuItemsProps
  extends React.ComponentProps<typeof PrimaryNavMenuItemsStyle> {
  children?: React.ReactNode;
  className?: string;
  menuRef?: React.Ref<HTMLUListElement> | undefined;
  variant?: 'main-menu' | 'submenu' | 'submenu-level-2' | 'panel';
  isTransparentNavigation?: boolean;
}

const PrimaryNavMenuItems: React.FC<PrimaryNavMenuItemsProps> = ({
  children,
  menuRef,
  variant = 'main-menu',
  isTransparentNavigation,
  ...rest
}: PrimaryNavMenuItemsProps) => {
  return (
    <PrimaryNavMenuItemsStyle
      ref={menuRef}
      variant={variant}
      isTransparentNavigation={isTransparentNavigation}
      data-test="navigation_menu_items"
      {...rest}
    >
      {children}
    </PrimaryNavMenuItemsStyle>
  );
};

PrimaryNavMenuItems.displayName = 'PrimaryNavMenuItems';

export default PrimaryNavMenuItems;
