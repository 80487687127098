import React, { useState } from 'react';

import CloseButtonDesktop from '@/components/layouts/header/close-button-desktop';
import PrimaryNavMenuItem from '@/components/layouts/header/primary-nav-menu-item';
import PrimaryNavMenuItems from '@/components/layouts/header/primary-nav-menu-items';
import PrimaryNavMenuLink from '@/components/layouts/header/primary-nav-menu-link';
import PrimaryNavTopWithBackMenu from '@/components/layouts/header/primary-nav-top-with-back-menu';
import { Box } from '@/components/shared/layout/box';
import { Container } from '@/components/shared/layout/container';
import { Grid } from '@/components/shared/layout/grid';
import { Image } from '@/components/shared/media/image';
import { IMenu } from '@/types/layout/menu';

interface SubmenuWithImageItemProps extends IMenu {
  handleMouseEnter?: () => void;
  className?: string;
}

const MenuItem = (submenuItem: SubmenuWithImageItemProps) => {
  const [toggle, setToggle] = useState<boolean>(false);

  return (
    <>
      <PrimaryNavMenuLink
        {...submenuItem}
        variant="submenu"
        onClickSubmenu={() => setToggle(!toggle)}
        onMouseEnter={submenuItem.handleMouseEnter}
        className={submenuItem.className}
        isSubmenu={!!submenuItem?.submenu?.length}
        isCurrent={toggle}
      />
      {!!submenuItem.submenu && submenuItem.submenu.length > 0 && (
        <PrimaryNavMenuItems
          variant="submenu-level-2"
          css={{
            '@maxlg': {
              display: toggle ? 'block' : 'none',
            },
          }}
        >
          {submenuItem.submenu.map((o, index) => (
            <PrimaryNavMenuItem key={index} variant="submenu-level-2">
              <PrimaryNavMenuLink {...o} variant="submenu-level-2" />
            </PrimaryNavMenuItem>
          ))}
        </PrimaryNavMenuItems>
      )}
    </>
  );
};

interface SubmenuWithImageProps extends IMenu {
  onClickBackMenu?: () => void;
  onClickCloseMenu?: () => void;
  itemsRef?: React.RefObject<HTMLDivElement[]> | undefined;
  index: number;
}

const SubmenuWithImage: React.FC<SubmenuWithImageProps> = ({
  onClickBackMenu,
  onClickCloseMenu,
  itemsRef,
  index,
  ...rest
}: SubmenuWithImageProps) => {
  const [mouseOver, setMouseOver] = useState<number>(0);

  const handleMouseOver = (subIndex: number) => {
    setMouseOver(subIndex);
  };
  return (
    <Container size="none" maxWidth="lg">
      <PrimaryNavTopWithBackMenu
        label={rest.label}
        onClickBackMenu={onClickBackMenu}
        onClickCloseMenu={onClickCloseMenu}
        isHideDesktop
      />
      <Box
        position="relative"
        ref={(ref) => {
          ref && itemsRef?.current && (itemsRef.current[index] = ref);
        }}
        css={{
          '@maxlg': {
            height: 'calc(var(--viewport-height) - var(--primary-nav-height))',
            overflow: 'hidden auto',
            '-webkit-overflow-scrolling': 'touch',
          },
        }}
      >
        <Grid
          css={{
            '@lg': {
              gridTemplateColumns:
                'minmax(372px, calc(480 / 1440 * 100%)) max-content',
            },
          }}
        >
          <Box
            position="relative"
            display={{ '@maxlg': 'none' }}
            css={{ aspectRatio: '4 / 3' }}
          >
            {rest?.submenu?.map((submenuItem, subIndex) => (
              <Box
                position="absolute"
                right="0"
                top="0"
                width="full"
                height="full"
                key={subIndex}
                className={subIndex === mouseOver ? 'is-current' : undefined}
                css={{
                  opacity: '0',
                  transition:
                    'opacity var(--transition-duration) var(--transition-easing)',
                  '&.is-current': {
                    opacity: '1',
                  },
                }}
              >
                {!!submenuItem.image && !!submenuItem.image.src && (
                  <Image
                    placeholder="shimmer"
                    src={submenuItem.image.src}
                    alt={submenuItem.image.alt}
                    width={1600}
                    height={1200}
                    layout="responsive"
                    aspectRatio="4by3"
                    sources={[
                      {
                        srcset: submenuItem.image.src,
                        options: {
                          width: 576,
                          crop: '4:3',
                        },
                      },
                    ]}
                  />
                )}
              </Box>
            ))}
          </Box>
          <Box
            marginY={{ '@lg': '10', '@xl': '16' }}
            paddingX={{ '@lg': '20' }}
          >
            <PrimaryNavMenuItems variant="submenu">
              {rest?.submenu?.map((submenuItem, subIndex) => (
                <PrimaryNavMenuItem
                  key={subIndex}
                  variant="submenu"
                  className={subIndex === mouseOver ? 'is-current' : ''}
                  isSubmenu={
                    !!submenuItem.submenu && submenuItem.submenu.length > 0
                  }
                >
                  <MenuItem
                    {...submenuItem}
                    handleMouseEnter={() => handleMouseOver(subIndex)}
                    className={subIndex === mouseOver ? 'is-current' : ''}
                  />
                </PrimaryNavMenuItem>
              ))}
            </PrimaryNavMenuItems>
          </Box>
        </Grid>
        <Box
          position="absolute"
          top="0"
          right="0"
          width="full"
          display={{ '@maxlg': 'none' }}
        >
          <Container size="desktop-only">
            <Box position="relative">
              <CloseButtonDesktop onClickCloseMenu={onClickCloseMenu} />
            </Box>
          </Container>
        </Box>
      </Box>
    </Container>
  );
};

SubmenuWithImage.displayName = 'SubmenuWithImage';

export default SubmenuWithImage;
