import React, { ComponentProps } from 'react';

import { styled } from '@/stitches.config';

const PrimaryNavMenuItemStyle = styled('li', {
  variants: {
    variant: {
      'main-menu': {
        '@maxlg': {
          borderBottom: '1px solid $gray300',
        },
      },
      submenu: {
        '@maxlg': {
          borderBottom: '1px solid $gray300',
        },
        '@lg': {
          '&.is-current': {
            '& > ul': {
              opacity: '1',
              visibility: 'visible',
              zIndex: '$1',
            },
          },
        },
      },
      'submenu-level-2': {
        '@maxlg': {
          '&:not(:last-child)': {
            borderBottom: '1px solid $gray300',
          },
        },
      },
      panel: {
        borderBottom: '1px solid $gray300',
      },
      model: {
        position: 'fixed',
        width: '100%',
        bottom: '$space-0',
        left: '$space-0',
      },
    },
    isSubmenu: {
      true: {},
    },
  },
  compoundVariants: [
    {
      variant: 'submenu',
      isSubmenu: 'true',
      css: {
        '@maxlg': {
          borderBottom: '1px solid $gray200',
        },
      },
    },
  ],
  defaultVariants: {
    variant: 'main-menu',
  },
});

interface PrimaryNavMenuItemProps
  extends ComponentProps<typeof PrimaryNavMenuItemStyle> {
  children?: React.ReactNode;
  variant?: 'main-menu' | 'submenu' | 'submenu-level-2' | 'panel' | 'model';
  css?: any;
  isSubmenu?: boolean;
}

const PrimaryNavMenuItem: React.FC<PrimaryNavMenuItemProps> = ({
  children,
  variant = 'main-menu',
  isSubmenu,
  ...rest
}: PrimaryNavMenuItemProps) => {
  return (
    <PrimaryNavMenuItemStyle
      variant={variant}
      isSubmenu={isSubmenu}
      data-test="navigation_menu_item"
      {...rest}
    >
      {children}
    </PrimaryNavMenuItemStyle>
  );
};

PrimaryNavMenuItem.displayName = 'PrimaryNavMenuItem';

export default PrimaryNavMenuItem;
