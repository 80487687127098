import React from 'react';

import { Icon } from '@/components/shared/element/icons';
import { Box } from '@/components/shared/layout/box';
import { ITest, ITracking } from '@/types/tracking';

interface PrimaryNavMenuCloseProps {
  onClickCloseMenu?: () => void;
  test?: ITest;
  tracking?: ITracking;
}

const PrimaryNavMenuClose: React.FC<PrimaryNavMenuCloseProps> = ({
  onClickCloseMenu,
  test,
  tracking,
}: PrimaryNavMenuCloseProps) => {
  return (
    <Box
      component="button"
      position="relative"
      height="full"
      backgroundColor="transparent"
      border="none"
      display="flex"
      alignItems="center"
      justifyContent="center"
      cursor="pointer"
      title="Menu Close Button"
      onClick={onClickCloseMenu}
      tracking={tracking}
      test={test}
    >
      <Icon icon="close" />
    </Box>
  );
};

PrimaryNavMenuClose.displayName = 'PrimaryNavMenuClose';

export default PrimaryNavMenuClose;
