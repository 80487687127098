import React from 'react';

import { Icon } from '@/components/shared/element/icons';
import { Link } from '@/components/shared/element/link';
import { Text } from '@/components/shared/element/text';
import { Grid } from '@/components/shared/layout/grid';
import { removeSpan } from '@/components/shared/utility/format';
import { css } from '@/stitches.config';
import { IMenu } from '@/types/layout/menu';

const SecondaryNavMenuLinkStyle = css({
  position: 'relative',
  cursor: 'pointer',
  padding: '$space-6 $space-8',
  display: 'flex',
  alignItems: 'center',
  columnGap: '$space-8',
  '&:not(:last-child)': {
    borderBottom: '1px solid $gray300',
  },
  '@md': {
    padding: '$space-9 $space-12',
  },
  '@lg': {
    padding: '$space-0',
    height: 'var(--secondary-nav-height)',
    columnGap: '$space-1',
    '&:not(:last-child)': {
      borderBottom: 'none',
    },
    '&.is-current': {
      '&::after': {
        content: '""',
        position: 'absolute',
        left: '50%',
        bottom: '$space-0',
        borderTop: 'none',
        borderLeft: '6px solid transparent',
        borderRight: '6px solid transparent',
        borderBottom: '6px solid $white',
        transform: 'translateX(-50%)',
        pointerEvents: 'none',
      },
    },
    '& *': {
      transition:
        'opacity var(--transition-duration) var(--transition-easing) !important',
    },
    '& i': {
      color: '$white',
      size: '$size-4',
      minWidth: '$size-4',
    },
    '@hover': {
      '&:hover': {
        '& > *': {
          opacity: '0.7',
        },
      },
    },
  },
  '@xl': {
    columnGap: '$space-1-5',
  },
});

const SecondaryNavMenuLink: React.FC<IMenu> = ({ ...rest }: IMenu) => {
  return (
    <Link
      className={`${
        rest.active ? 'is-current' : ''
      } ${SecondaryNavMenuLinkStyle}`}
      href={rest.url}
      target={rest.target || '_self'}
      tracking={{
        dataTrack: 'secondary-nav',
        dataTrackUrl: rest.url,
        dataTrackText: removeSpan(rest.label),
      }}
      test={{
        dataTest: 'navigation_menu_link',
      }}
    >
      {!!rest?.icon && <Icon icon={rest?.icon} size="lg" color="icon-red" />}
      {rest.description ? (
        <Grid rowGap="1">
          <Text
            size={{ '@initial': 'text-base', '@lg': 'text-button-2' }}
            font={{
              '@initial': 'bold',
              '@lg': rest.active ? 'bold' : 'base',
            }}
            variant={{ '@initial': 'text-gray-darkest', '@lg': 'text-white' }}
            wrap
            dangerouslySetInnerHTML={{ __html: rest.label }}
            data-test="title"
          />
          {!!rest.description && (
            <Text
              size="text-body-2"
              variant="text-gray-dark"
              css={{ '@lg': { display: 'none' } }}
              wrap
              dangerouslySetInnerHTML={{ __html: rest.description }}
              data-test="description"
            />
          )}
        </Grid>
      ) : (
        <Text
          size={{ '@initial': 'text-base', '@lg': 'text-button-2' }}
          font={{ '@initial': 'bold', '@lg': rest.active ? 'bold' : 'base' }}
          variant={{ '@initial': 'text-gray-darkest', '@lg': 'text-white' }}
          wrap
          dangerouslySetInnerHTML={{ __html: rest.label }}
          data-test="title"
        />
      )}
    </Link>
  );
};

SecondaryNavMenuLink.displayName = 'SecondaryNavMenuLink';

export default SecondaryNavMenuLink;
