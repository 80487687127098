import React from 'react';

import { Box } from '@/components/shared/layout/box';
import { CSS, styled } from '@/stitches.config';

const SecondaryNavMenuItemsStyled = styled(Box, {
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'wrap',
  columnGap: '$space-2',
  '@lg': {
    flexDirection: 'row',
  },
  '@xl': {
    columnGap: '$space-4',
  },
});

interface SecondaryNavMenuItemsProps {
  children?: React.ReactNode;
  css?: CSS;
}

const SecondaryNavMenuItems: React.FC<SecondaryNavMenuItemsProps> = ({
  children,
  css,
}: SecondaryNavMenuItemsProps) => {
  return (
    <SecondaryNavMenuItemsStyled
      component="nav"
      position="relative"
      backgroundColor={{ '@initial': 'white', '@lg': 'transparent' }}
      test={{ dataTest: 'navigation_menu_items' }}
      css={css}
    >
      {children}
    </SecondaryNavMenuItemsStyled>
  );
};

SecondaryNavMenuItems.displayName = 'SecondaryNavMenuItems';

export default SecondaryNavMenuItems;
