import React from 'react';

import { IconUero5Max } from '@/components/layouts/header/icon-uero-5-max';
import { Button } from '@/components/shared/buttons/button';
import { Link } from '@/components/shared/element/link';
import NewBadge from '@/components/shared/element/new-badge';
import { Text } from '@/components/shared/element/text';
import { Grid } from '@/components/shared/layout/grid';
import { Image } from '@/components/shared/media/image';
import { CSS, css, styled } from '@/stitches.config';
import { IWeb } from '@/types/config';
import { IMenu } from '@/types/layout/menu';

const SubmenuModelLinkStyle = css({
  display: 'grid',
  gap: '$space-4',
  variants: {
    variant: {
      lcv: {
        alignItems: 'center',
        gridTemplateColumns: 'auto 148px',
        '@xs': {
          gridTemplateColumns: 'auto 168px',
        },
        '@md': {
          gridTemplateColumns: '200px',
        },
      },
      cv: {
        alignContent: 'space-between',
        gridTemplateColumns: 'minmax(auto, 208px)',
        '@lg': {
          gridTemplateColumns: 'minmax(auto, 180px)',
        },
        '@xl': {
          gridTemplateColumns: 'minmax(auto, 208px)',
        },
      },
    },
  },
});

const SubmenuModelLinkImage = styled('div', {
  position: 'relative',
  variants: {
    maxWidth: {
      true: {
        width: '100%',
        maxWidth: 255,
        mx: 'auto',
      },
    },
  },
});

export const Badge = styled('div', {
  width: 'calc(80 / 208 * 100%)',
  mx: 'auto',
  fontSize: 0,
  pointerEvents: 'none',
  '& svg': {
    width: '100%',
    height: 'auto',
  },
});

interface SubmenuModelLinkProps
  extends IMenu,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    React.ComponentProps<typeof SubmenuModelLinkStyle> {
  variant: IWeb;
  forBrochure?: boolean;
  new?: boolean;
  dataTrack: string;
  dataTest: string;
  transparentCss?: CSS;
}

const SubmenuModelLink: React.FC<SubmenuModelLinkProps> = ({
  variant,
  forBrochure,
  dataTrack,
  dataTest,
  transparentCss,
  ...rest
}: SubmenuModelLinkProps) => {
  return (
    <Link
      className={SubmenuModelLinkStyle({ variant: variant })}
      href={rest.url}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      target={rest.target}
      tracking={{
        dataTrack: dataTrack,
        dataTrackText: rest.label,
        dataTrackUrl: rest.url,
      }}
      test={{
        dataTest: dataTest,
      }}
      hoverColor={!forBrochure ? 'red' : undefined}
      {...rest}
      css={
        transparentCss
          ? {
              ...transparentCss,
              '@lg': {
                opacity: 'var(--submenu-opacity)',
                transform: 'var(--submenu-transform)',
                transitionProperty: 'var(--submenu-transition-property)',
                transitionDelay: 'var(--submenu-transition-delay)',
                transitionDuration: 'var(--submenu-transition-duration)',
                transitionTimingFunction: 'var(--submenu-transition-easing)',
                willChange: 'var(--submenu-will-change)',
              },
            }
          : undefined
      }
    >
      <Grid rowGap="1">
        {!!rest.label && (
          <Text size="text-body-1" font="bold" data-test="title">
            {rest.label}
          </Text>
        )}
        {!!rest.description && (
          <Text
            size="text-button-2"
            variant="text-gray-dark"
            data-test="description"
          >
            {rest.description}
          </Text>
        )}
      </Grid>
      {!!rest.image && !!rest.image.src && (
        <SubmenuModelLinkImage maxWidth={forBrochure}>
          {!!rest.new && <NewBadge />}
          <Image
            priority={rest.image.priority}
            src={rest.image.src}
            alt={rest.image.alt}
            width={rest.image.width}
            height={rest.image.height}
            layout="responsive"
            aspectRatio="4by3"
            sources={[
              {
                srcset: rest.image.src,
                media: { minWidth: 768 },
                options: { width: 256, crop: '4:3' },
              },
              {
                srcset: rest.image.src,
                media: { maxWidth: 767 },
                options: { width: 185, crop: '4:3' },
              },
            ]}
          />
          {rest.engineType === 'euro-5' && (
            <Badge>
              <IconUero5Max />
            </Badge>
          )}
        </SubmenuModelLinkImage>
      )}

      {!!forBrochure && (
        <Button
          label="ดาวน์โหลด"
          icon="file-download"
          border
          variant="button-gray"
          size="md"
          css={{ justifySelf: 'center' }}
          tracking={{
            dataTrack: dataTrack,
            dataTrackText: 'ดาวน์โหลด',
            dataTrackUrl: rest.url,
            dataTrackValue: rest.label,
          }}
        />
      )}
    </Link>
  );
};

SubmenuModelLink.displayName = 'SubmenuModelLink';

export default SubmenuModelLink;
