import React, { useCallback } from 'react';

import { Box } from '@/components/shared/layout/box';
import { CSS } from '@/stitches.config';
import { ITest, ITracking } from '@/types/tracking';

interface PrimaryNavMenuHamburgerProps {
  onClickMenu?: () => void;
  isClickMenu?: boolean;
  onMouseEnter?: () => void;
  isTransparentNavigation?: boolean;
  css?: CSS;
  test?: ITest;
  tracking?: ITracking;
}

const PrimaryNavMenuHamburger: React.FC<PrimaryNavMenuHamburgerProps> = ({
  css,
  onClickMenu,
  isClickMenu = false,
  onMouseEnter,
  test,
  tracking,
  isTransparentNavigation,
}) => {
  const handleClick = useCallback(() => {
    if (onClickMenu) {
      window.requestAnimationFrame(onClickMenu);
    }
  }, [onClickMenu]);

  const handleHover = useCallback(() => {
    if (!isTransparentNavigation) return;

    if (onMouseEnter && typeof window.requestIdleCallback === 'function') {
      window.requestIdleCallback(onMouseEnter);
    }
  }, [onMouseEnter, isTransparentNavigation]);

  return (
    <Box
      component="button"
      display="flex"
      direction="column"
      alignItems="end"
      justifyContent="center"
      width="6"
      height="full"
      backgroundColor="transparent"
      border="none"
      cursor="pointer"
      onClick={handleClick}
      onMouseEnter={handleHover}
      aria-label={tracking?.dataTrackText}
      tracking={tracking}
      test={test}
      css={css}
    >
      <Box
        component="span"
        display="block"
        width="full"
        height="0-5"
        backgroundColor={
          !isTransparentNavigation || isClickMenu ? 'gray900' : 'white'
        }
        marginBottom="1-5"
      />
      <Box
        component="span"
        display="block"
        width="full"
        height="0-5"
        backgroundColor={
          !isTransparentNavigation || isClickMenu ? 'gray900' : 'white'
        }
        marginBottom="1-5"
      />
      <Box
        component="span"
        display="block"
        width="full"
        height="0-5"
        backgroundColor={
          !isTransparentNavigation || isClickMenu ? 'gray900' : 'white'
        }
      />
    </Box>
  );
};
PrimaryNavMenuHamburger.displayName = 'PrimaryNavMenuHamburger';
export default PrimaryNavMenuHamburger;
