import React from 'react';

import PrimaryNavMenuClose from '@/components/layouts/header/primary-nav-menu-close';
import { Box } from '@/components/shared/layout/box';
import { styled } from '@/stitches.config';

const PrimaryNavTopWithLogoInnerStyle = styled(Box, {
  gridTemplateColumns: 'auto max-content',
});

const PrimaryNavTopWithLogoStyle = styled('div', {
  width: '100%',
  height: 'calc(var(--primary-nav-height) + 1px)',
  backgroundColor: '$white',
  borderBottom: '1px solid $gray300',
  variants: {
    variant: {
      'main-nav': {
        position: 'absolute',
        top: '$space-0',
        left: '$space-0',
        zIndex: '1',
        transform: 'translateZ(0)',
        transitionProperty: 'transform',
        transitionDuration: '0.6s',
        transitionTimingFunction: 'var(--transition-easing)',
        willChange: 'transform',
        [`& ${PrimaryNavTopWithLogoInnerStyle}`]: {
          '@sm': {
            paddingLeft: '$space-12',
            paddingRight: '$space-12',
          },
        },
        '&.is-submenu-open': {
          transform: 'translateX(-100%)',
        },
      },
      panel: {},
    },
  },
});

interface PrimaryNavTopWithLogoProps
  extends React.ComponentProps<typeof PrimaryNavTopWithLogoStyle> {
  onClickCloseMenu?: () => void;
  variant?: 'main-nav' | 'panel';
  children?: React.ReactNode;
  className?: string;
}

const PrimaryNavTopWithLogo: React.FC<PrimaryNavTopWithLogoProps> = ({
  children,
  variant,
  onClickCloseMenu,
  className,
}: PrimaryNavTopWithLogoProps) => {
  return (
    <PrimaryNavTopWithLogoStyle
      variant={variant}
      data-test="navigation_menu_top"
      className={className}
    >
      <PrimaryNavTopWithLogoInnerStyle
        position="relative"
        width="full"
        height="full"
        display="grid"
        paddingX="6"
        columnGap="6"
      >
        {children}
        <PrimaryNavMenuClose
          onClickCloseMenu={onClickCloseMenu}
          tracking={{
            dataTrack: 'primary-nav',
            dataTrackText: 'Menu Close Button',
          }}
          test={{
            dataTest: 'menu_close_button',
          }}
        />
      </PrimaryNavTopWithLogoInnerStyle>
    </PrimaryNavTopWithLogoStyle>
  );
};

PrimaryNavTopWithLogo.displayName = 'PrimaryNavTopWithLogo';

export default PrimaryNavTopWithLogo;
