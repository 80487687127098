export const IconUero5Max: React.FC = () => (
  <svg
    width="80"
    height="7"
    viewBox="0 0 80 7"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.1983 3.46608C3.10795 3.46457 3.10418 3.43973 3.10569 3.35992V2.55053C3.10569 2.46621 3.13053 2.46094 3.21637 2.46094H7.29415C7.37998 2.46094 7.40483 2.46621 7.40483 2.55053V3.35992C7.40633 3.43973 7.40257 3.46382 7.31222 3.46608H3.1983Z"
      fill="#284C94"
    />
    <path
      d="M60.6523 5.12385C60.6094 5.01241 59.0516 0.810403 59.0177 0.704242C58.9899 0.61841 58.9048 0.609375 58.8769 0.609375H56.9141C56.8682 0.609375 56.8252 0.634221 56.8042 0.669608C56.7831 0.703489 55.2946 3.02171 55.1711 3.21897C55.1658 3.22349 55.1613 3.22801 55.159 3.23253C55.153 3.24382 55.1455 3.25286 55.1372 3.26038C55.1146 3.28448 55.083 3.29953 55.0484 3.29953C55.007 3.29953 54.9723 3.27695 54.9497 3.24457C54.9467 3.24081 54.9422 3.23704 54.9399 3.23253C54.9377 3.22876 54.9324 3.22349 54.9279 3.21897C54.8052 3.02171 53.3159 0.703489 53.2948 0.669608C53.273 0.634974 53.2308 0.609375 53.1849 0.609375H51.2221C51.1942 0.609375 51.1091 0.619163 51.0813 0.704242C51.0474 0.810403 49.4888 5.01241 49.4459 5.12385C49.403 5.23452 49.4919 5.31132 49.5438 5.31132H51.7423C51.8161 5.30153 51.8402 5.2541 51.8553 5.2157C51.8703 5.1773 52.5502 3.33342 52.5781 3.2634C52.5781 3.26189 52.5803 3.26189 52.5811 3.25963C52.5999 3.21295 52.6443 3.17907 52.6963 3.17907C52.7482 3.17907 52.7874 3.20919 52.8077 3.2521C52.9003 3.3944 54.0342 5.16601 54.0756 5.23076C54.1185 5.29702 54.1765 5.30379 54.1765 5.30379H55.9225C55.9225 5.30379 55.9805 5.29702 56.0234 5.23076C56.0648 5.16601 57.1987 3.3944 57.2913 3.2521C57.3116 3.20919 57.353 3.17907 57.4027 3.17907C57.4547 3.17907 57.4991 3.2122 57.5179 3.25963C57.5187 3.26114 57.5202 3.26114 57.5209 3.2634C57.5488 3.33342 58.2287 5.17806 58.2437 5.2157C58.2588 5.2541 58.2836 5.30153 58.3567 5.31132H60.5552C60.6071 5.31132 60.696 5.23452 60.6531 5.12385H60.6523Z"
      fill="#DC0000"
    />
    <path
      d="M77.4766 3.07366C77.4457 3.04957 77.4246 3.01493 77.4246 2.97277C77.4246 2.93061 77.4457 2.89597 77.4766 2.87339L79.9499 0.829979C80.0034 0.78631 80.0079 0.728335 79.9943 0.689937C79.9808 0.650785 79.9424 0.609375 79.8739 0.609375H76.7847C76.738 0.609375 76.6823 0.629704 76.6454 0.659068L75.6749 1.46092H75.6741C75.653 1.47824 75.6274 1.49028 75.5981 1.49028C75.5687 1.49028 75.5446 1.47899 75.5243 1.46167L74.5523 0.659068C74.5161 0.628951 74.4597 0.609375 74.413 0.609375H71.323C71.2545 0.609375 71.2161 0.650785 71.2026 0.689937C71.189 0.729088 71.1935 0.78631 71.2462 0.829979L73.7248 2.8764C73.7542 2.89899 73.7745 2.93287 73.7745 2.97352C73.7745 3.01418 73.7534 3.04881 73.7233 3.0714L71.2462 5.11556C71.1928 5.15999 71.1882 5.21721 71.2018 5.25561C71.2153 5.29476 71.2538 5.33692 71.3223 5.33692H74.4122C74.4589 5.33692 74.5146 5.31659 74.5515 5.28647L75.5235 4.48312C75.5439 4.46655 75.5687 4.45526 75.5973 4.45526C75.6259 4.45526 75.6523 4.4673 75.6734 4.48462L76.6446 5.28647C76.6815 5.31659 76.7372 5.33692 76.7839 5.33692H79.8731C79.9416 5.33692 79.98 5.29476 79.9936 5.25561C80.0071 5.21645 80.0026 5.15923 79.9491 5.11556L77.4751 3.07366H77.4766Z"
      fill="#DC0000"
    />
    <path
      d="M65.6521 2.00077C65.573 2.00077 65.5399 2.0452 65.5241 2.08359C65.509 2.12124 63.9814 5.16828 63.9452 5.24433C63.9091 5.32037 63.83 5.32639 63.83 5.32639H61.6714C61.5193 5.32639 61.5472 5.16828 61.5653 5.12988C61.5833 5.09148 63.7389 0.771266 63.7818 0.688445C63.8247 0.606378 63.9031 0.609389 63.9031 0.609389H67.7662C67.7662 0.609389 67.8446 0.606378 67.8875 0.688445C67.9296 0.770513 70.0792 5.07944 70.098 5.11708C70.1161 5.15548 70.1432 5.31359 69.9919 5.31359H67.8393C67.8393 5.31359 67.7602 5.30682 67.7241 5.23153C67.6879 5.15548 66.1648 2.11748 66.1497 2.07908C66.1347 2.04068 66.1016 1.99701 66.0225 1.99701L65.6521 2.00077Z"
      fill="#DC0000"
    />
    <path
      d="M9.97293 5.40232C9.51516 5.39328 9.24411 5.05146 9.25315 4.6622V3.05172V0.585177C9.25315 0.582918 9.25465 0.580659 9.25465 0.5784C9.25767 0.553554 9.27649 0.533979 9.30209 0.527202C9.30661 0.524944 9.31188 0.523438 9.31639 0.523438H11.6474H11.6489C11.6489 0.523438 11.6497 0.523438 11.6504 0.523438C11.6888 0.523438 11.7197 0.551296 11.7197 0.585177V2.91394V4.24735C11.7197 4.29554 11.7528 4.30608 11.8048 4.30608H15.0182C15.0702 4.30608 15.1033 4.29554 15.1033 4.24735V2.91394V0.585177C15.1033 0.550543 15.1334 0.523438 15.1718 0.523438C15.1718 0.523438 15.1726 0.523438 15.1733 0.523438H15.1748H17.5058C17.5104 0.523438 17.5156 0.524944 17.5201 0.527202C17.5457 0.534731 17.5646 0.553554 17.5676 0.5784C17.5676 0.580659 17.5691 0.582165 17.5691 0.585177V3.05172V4.6622C17.5781 5.05146 17.3071 5.39328 16.8493 5.40232H9.97143H9.97293Z"
      fill="#284C94"
    />
    <path
      d="M8.22731 5.39558C8.30486 5.39558 8.33121 5.3474 8.33121 5.29545V4.40475C8.33121 4.34753 8.31088 4.29407 8.21902 4.29407H6.95639L2.54582 4.30235C2.50817 4.30235 2.46902 4.28278 2.46827 4.23835V2.25669C2.46827 2.18968 2.44117 2.15128 2.37943 2.12493L1.5452 1.72513C1.44958 1.6792 1.46689 1.62424 1.57682 1.61972L7.15817 1.62123C7.15817 1.62123 8.11738 1.62123 8.20999 1.62123C8.3026 1.62123 8.33422 1.55572 8.33422 1.51055V0.630393C8.33422 0.599524 8.32142 0.523479 8.21526 0.523479H0.812613C0.376676 0.519715 0.000218981 0.769682 0.000218981 1.17851V4.65848C-0.00881598 5.04774 0.262233 5.38956 0.720004 5.39859L8.22731 5.39558Z"
      fill="#284C94"
    />
    <path
      d="M27.6508 6.18234L26.0404 3.56446C25.999 3.49895 26.0027 3.46281 26.0878 3.46356L26.3649 3.46206C26.8001 3.46206 27.1765 3.21209 27.1765 2.80326V1.29969L27.1727 1.30647V1.26355C27.1818 0.874295 26.9107 0.532472 26.453 0.523438H18.8267C18.6904 0.523438 18.6324 0.602494 18.637 0.688326V5.15687C18.637 5.32326 18.719 5.40232 18.8726 5.40232H20.9349C21.1065 5.40232 21.172 5.32176 21.172 5.15837V3.2776H21.1735V2.27848C21.1735 2.21147 21.1464 2.17307 21.0847 2.14672L20.3807 1.80941L20.2075 1.72735C20.1127 1.68217 20.1292 1.62646 20.2392 1.62194H23.4375H24.7077C24.7642 1.62194 24.7747 1.66184 24.7747 1.67765V1.92687V2.30709C24.7747 2.30709 24.7747 2.30709 24.7747 2.30784V2.41325C24.7747 2.42906 24.7642 2.46897 24.7077 2.46897H24.6174C24.6106 2.46897 24.6038 2.46972 24.5963 2.46972H24.1799H21.9099C21.8376 2.46972 21.8165 2.47499 21.8165 2.55932V3.36795C21.815 3.44851 21.818 3.4726 21.8941 3.47411H23.311C23.415 3.47411 23.5038 3.49594 23.5573 3.58102L25.1768 6.20643C25.2317 6.29226 25.2731 6.32915 25.3793 6.32915H27.5409C27.6644 6.32915 27.7088 6.27645 27.6486 6.18083L27.6508 6.18234Z"
      fill="#284C94"
    />
    <path
      d="M36.591 1.26355C36.6008 0.875048 36.3253 0.532472 35.8615 0.523438H28.9023C28.4611 0.523438 28.0794 0.773405 28.0794 1.18224V4.6622C28.0696 5.05146 28.3451 5.39328 28.8082 5.40232H35.7674C36.2086 5.40232 36.5903 5.15235 36.5903 4.74352V1.26355H36.591ZM34.2201 4.25036C34.2201 4.28123 34.1877 4.30532 34.1508 4.30532H30.6189C30.582 4.30532 30.5497 4.28048 30.5497 4.25036V3.28512H30.5504V2.28601C30.5504 2.219 30.5211 2.17382 30.4601 2.14672L30.2417 2.04357L29.5731 1.72735C29.4768 1.68142 29.4933 1.6257 29.6048 1.62194H34.1531C34.2096 1.62194 34.2201 1.66184 34.2201 1.67765V4.25036Z"
      fill="#284C94"
    />
    <path
      d="M38.2897 0H47.9533C48.0497 0 48.1069 0.0880908 48.1069 0.147571C48.1069 0.147571 48.1159 1.03224 48.1159 1.08645C48.1159 1.14066 48.0632 1.2265 47.9624 1.2265H41.4737C41.4444 1.2265 41.2998 1.22348 41.2998 1.40494V2.05922C41.2998 2.08331 41.3089 2.19926 41.4662 2.19926H46.83C46.83 2.19926 47.8389 2.14957 48.2432 2.9838C48.4344 3.37531 48.5888 4.04917 48.4841 4.78778C48.3795 5.52714 48.1348 5.80797 47.9375 6.0075C47.7395 6.20627 47.549 6.33426 47.2072 6.39977C47.0159 6.44343 46.8322 6.43741 46.7088 6.43741H38.2452C38.1534 6.43741 38.0917 6.37191 38.0917 6.30415V5.104C38.0917 5.03549 38.1293 4.9474 38.252 4.9474H44.4952C44.8024 4.9474 45.1276 4.93987 45.2692 4.86909C45.4152 4.79681 45.5146 4.66505 45.5146 4.43692C45.5146 4.20879 45.5154 4.3285 45.5146 4.24342C45.5139 4.15458 45.4943 3.90762 45.2315 3.82631C45.0983 3.78716 44.9085 3.77963 44.7881 3.77963C44.6789 3.77963 38.3574 3.78038 38.2859 3.78038C38.2144 3.78038 38.0879 3.73445 38.0879 3.58839V0.171664C38.0879 0.0767971 38.1557 0 38.2889 0H38.2897Z"
      fill="#284C94"
    />
  </svg>
);
IconUero5Max.displayName = 'IconUero5Max';
