import { Box } from '@/components/shared/layout/box';
import { styled } from '@/stitches.config';

export const DESKTOP_TRANSPARENT_SUBMENU_BACKGROUND_HEIGHT_KEY =
  '--desktop-transparent-submenu-background-height';

export const StyledHeader = styled(Box, {
  position: 'relative',
  width: '$size-full',
  backgroundColor: '$white',
  zIndex: '$header',
  variants: {
    isTransparentNavigation: {
      true: {
        position: 'absolute',
        backgroundColor: '$transparent',
        '@lg': {
          top: '$space-0',
          left: '$space-0',
          '&::before': {
            content: '""',
            willChange: 'height, opacity, transform',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: `var(${DESKTOP_TRANSPARENT_SUBMENU_BACKGROUND_HEIGHT_KEY})`,
            backgroundColor: '$white',
            pointerEvents: 'none',
            transitionProperty: 'height, opacity, transform',
            transitionDuration: '0.35s',
            transitionTimingFunction: 'cubic-bezier(0.16, 1, 0.3, 1)',
            zIndex: '-1',
          },
        },
      },
    },
    transparentNavigationAnimationOut: {
      true: {},
    },
  },
  compoundVariants: [
    {
      isTransparentNavigation: 'true',
      transparentNavigationAnimationOut: 'true',
      css: {
        '&::before': {
          opacity: 0,
          transform: 'translateY(-100px)',
          transitionDuration: '0.6s',
          transitionTimingFunction: 'var(--transition-easing)',
        },
      },
    },
  ],
});

export const StyledSubmenu = styled(Box, {
  '@lg': {
    position: 'absolute',
    width: '100%',
    left: '$space-0',
    top: '100%',
    zIndex: '-1',
    transform: 'translateY(-40px)',
    opacity: 0,
    pointerEvents: 'none',
    transitionProperty: 'opacity, transform',
    transitionDuration: '0.6s',
    transitionTimingFunction: 'var(--transition-easing)',
    '&.enter': {
      transform: 'translateY(0)',
      opacity: 1,
      pointerEvents: 'all',
    },
  },
  '@maxlg': {
    position: 'fixed',
    top: '$space-0',
    right: '$space-0',
    width: '100%',
    height: 'var(--viewport-height)',
    zIndex: '$2',
    pointerEvents: 'none',
    transform: 'translateX(100%)',
    transitionProperty: 'transform',
    transitionDuration: '0.6s',
    transitionTimingFunction: 'var(--transition-easing)',
    '&.enter': {
      transform: 'translateX(0)',
      pointerEvents: 'all',
    },
  },
  variants: {
    isTransparentNavigation: {
      true: {
        '@lg': {
          top: '$space-0',
          paddingTop: '$space-24',
          backgroundColor: 'transparent',
          '&.enter': {
            opacity: 1,
            transform: 'translateY(0)',
          },
          '&.leave': {
            opacity: 0,
            transform: 'translateY(0)',
            transitionDuration: '0.2s',
          },
        },
      },
    },
  },
});

export const PrimaryNavStyle = styled(Box, {
  backgroundColor: '$white',
  boxShadow: '$shadow-200',
  variants: {
    isTransparentNavigation: {
      true: {
        '@lg': {
          backgroundColor: 'transparent',
          boxShadow: 'none',
        },
        '@maxlg': {
          paddingTop: '$space-2',
          '&:not(.is-buying-tools-open)': {
            backgroundColor: 'transparent',
            boxShadow: 'none',
          },
        },
      },
    },
  },
});
